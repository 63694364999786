import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import moment from 'moment';

import { Grid, MenuItem, TextField } from '@mui/material';

import Bar from '@/components/BI/graphicBar';
import HeaderTotalGraphic from '@/components/organisms/headers/HeaderTotalGraphic';
import LoadingComponent from '@/components/templates/LoadingComponent';
import useManagerPanelService from '@/services/managerPanel';
import useReportService from '@/services/report';
import { handleDownloadData } from '@/utils/helpers/downloads';

import ReportsPerConsultant from './components/ReportsPerConsultant';
import { Div, PaperTitle, Section, Text } from './styles';

const monthTranslations = {
    January: 'Janeiro',
    February: 'Fevereiro',
    March: 'Março',
    April: 'Abril',
    May: 'Maio',
    June: 'Junho',
    July: 'Julho',
    August: 'Agosto',
    September: 'Setembro',
    October: 'Outubro',
    November: 'Novembro',
    December: 'Dezembro'
};

const allMonths = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
];

const Reports = ({ idEnterprise }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [filterPer, setFilterPer] = useState(null);
    const [consultantYearFilter, setConsultantYearFilter] = useState(null);
    const [consultantMonthFilter, setConsultantMonthFilter] = useState(moment().month() + 1);
    const [constultantsFilter, setConsultantsFilter] = useState(null);
    const [graphBarData, setGraphBarData] = useState([]);

    const currentUserRole = Number(sessionStorage.getItem('currentUserRole'));

    const reportService = useReportService();
    const [enterpriseReports, setEnterpriseReports] = useState('');
    const dispatch = useDispatch();
    const { clickedReportButton } = useSelector((state) => state.managerPanelReducer);

    const { getReportsPDFReport } = useManagerPanelService();

    const options = {
        chartArea: { width: '80%', height: '80%' },
        colors: ['#73A4F7'],
        explorer: { axis: 'horizontal', keepInBounds: false },
        orientation: 'horizontal',
        legend: { position: 'none' },
        fontSize: 12,
        tooltip: {
            ignoreBounds: false,
            textStyle: {
                bold: true
            }
        },
        annotations: {
            textStyle: {
                fontSize: 15,
                bold: true,
                color: '#000'
            }
        },
        hAxis: {
            title: 'Meses',
            titleTextStyle: {
                italic: false,
                color: '#9BA1A9'
            }
        },
        vAxis: {
            title: 'Quant. de relatórios',
            titleTextStyle: {
                italic: false,
                color: '#9BA1A9'
            }
        }
    };

    function handleGraphBarData(data) {
        if (!data || !Object.values(data).length) {
            return;
        }

        const formattedData = [['Mês', 'Quantidade', { role: 'annotation' }]];

        allMonths.forEach((month) => {
            const translatedMonth = monthTranslations[month];
            const buscando = data.find((buscando) => buscando.month == month);
            const count = buscando ? buscando.count : 0;
            const annotation = count;
            formattedData.push([translatedMonth, count, annotation]);
        });

        setGraphBarData(formattedData);
    }

    const handleGraphData = async (year) => {
        setIsLoading(true);
        setGraphBarData([]);

        try {
            const resp = await reportService.getCountReportsPerMonth(idEnterprise, year, currentUserRole);

            setEnterpriseReports(resp);
            if (filterPer === null) {
                setFilterPer(resp.years[0]);
                setConsultantYearFilter(resp.years[0]);
            }

            handleGraphBarData(resp?.countPerMonth.countPerMonth);
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
        // .then((resp) => {
        //     setIsLoading(false);
        // })
        // .catch((error) => {
        //     console.log(error);
        //     setIsLoading(false);
        // });
    };

    const handleSelectOption = (option) => {
        setFilterPer(option);
        handleGraphData(option);
    };

    const handleReport = async () => {
        try {
            dispatch({ type: 'MANAGER_PANEL_REPORT_IS_LOADING', payload: true });
            const { data } = await getReportsPDFReport(
                idEnterprise,
                filterPer ?? '',
                consultantYearFilter ?? '',
                consultantMonthFilter ?? '',
                currentUserRole ?? ''
            );

            if (data) {
                handleDownloadData(data, `relatorios_aprovados_${filterPer}`);
            }
        } catch (error) {
            console.log(error);
        } finally {
            dispatch({ type: 'MANAGER_PANEL_RESET' });
        }
    };

    useEffect(() => {
        handleGraphData('');
    }, []);

    useEffect(() => {
        if (clickedReportButton) {
            handleReport();
        }
    }, [clickedReportButton]);

    // if (isLoading) {
    //     return <LoadingComponent hideLogo />;
    // }

    return (
        <>
            <Section>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <HeaderTotalGraphic
                            title="Total Consolidado:"
                            description={(enterpriseReports?.totalEnterpriseReports ?? 0) + ' relatórios aprovados'}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <PaperTitle>Relatórios aprovados / ano</PaperTitle>
                            </Grid>

                            <Grid item xs={12}>
                                <Grid container justifyContent={'space-between'} alignItems={'center'}>
                                    <Grid item>
                                        <Div minWidth={'100px'}>
                                            <TextField
                                                fullWidth
                                                size={'small'}
                                                select
                                                value={filterPer}
                                                onChange={(evt) => handleSelectOption(evt.target.value)}
                                            >
                                                {enterpriseReports.years
                                                    ? enterpriseReports.years.map((year) => (
                                                          <MenuItem key={year} value={year}>
                                                              {year}
                                                          </MenuItem>
                                                      ))
                                                    : null}
                                            </TextField>
                                        </Div>
                                    </Grid>

                                    <Grid item>
                                        <PaperTitle style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                                            {filterPer} <PaperTitle>|</PaperTitle>{' '}
                                            <Text>{enterpriseReports?.countPerMonth?.totalReportsYear} relatórios aprovados</Text>
                                        </PaperTitle>
                                    </Grid>

                                    <Grid
                                        item
                                        xs={12}
                                        style={{
                                            height: enterpriseReports?.countPerMonth?.countPerMonth.length ? '28.75rem' : '10rem'
                                        }}
                                    >
                                        {isLoading ? (
                                            <LoadingComponent hideLogo />
                                        ) : (
                                            <Bar height="100%" width="100%" allData={graphBarData} options={options} />
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                {/* )} */}
            </Section>
            {enterpriseReports.years ? (
                <Section style={{ marginTop: '2rem' }}>
                    <ReportsPerConsultant
                        idEnterprise={idEnterprise}
                        yearsOptions={enterpriseReports.years ?? []}
                        setConsultantYearFilter={setConsultantYearFilter}
                        setConsultantMonthFilter={setConsultantMonthFilter}
                    />
                </Section>
            ) : null}
        </>
    );
};

export default Reports;
