import useApi from '../api';

const useEnterpriseService = () => {
    const { api } = useApi();

    return {
        sendInvite: (data) =>
            api
                .post('enterprise/invite', data, {
                    headers: { 'Content-Type': 'application/json' }
                })
                .then((resp) => resp.data),

        getEnterpriseInviteUser: (token) => api.get(`enterprise/invite/${token}`),

        getEnterpriseEmployees: (idEnterprise, currentUserRole) =>
            api.get(`enterprise/${idEnterprise}/employees`, {
                params: {
                    currentUserRole
                }
            }),

        getEnterpriseConsultats: (idEnterprise, currentUserRole) =>
            api.get(`enterprise/${idEnterprise}/consultants_enterprise?currentUserRole=${currentUserRole}`).then((resp) => resp.data),

        getEnterpriseArchivedEmployees: (idEnterprise) => api.get(`enterprise/${idEnterprise}/employees-archived`),

        approveOrArchiveEmployees: (idEnterprise, idEmployee, data) =>
            api.put(`enterprise/${idEnterprise}/employee-approve-archive/${idEmployee}`, data, {
                headers: { 'Content-Type': 'application/json' }
            }),

        updateEmployee: (idUser, data) =>
            api.post(`user/${idUser}`, data, {
                headers: { 'Content-Type': 'multipart/form-data' }
            }),

        getEnterprisePerId: (id) => api.get(`enterprise/${id}`).then((resp) => resp.data),
        search: (search) =>
            api
                .get('enterprise', {
                    params: {
                        search: search
                    }
                })
                .then((resp) => resp.data),
        getEnterpriseEmployeesInvites: (idEnterprise) => api.get(`${idEnterprise}/enterprise-invites`),
        removeEmplyeeInvite: (id) => api.delete(`enterprise-invite/${id}`).then((resp) => resp),
        getGeneralData: (id, currentUserRole = null) =>
            api.get(`enterprise/${id}/general?currentUserRole=${currentUserRole}`).then((resp) => resp),
        getPropertyCountPerConsultant: (id, year, filterPerAllOrBlocked) =>
            api
                .get(`enterprise/${id}/get_property_count`, {
                    params: {
                        year,
                        filterPerAllOrBlocked
                    }
                })
                .then((resp) => resp.data)
    };
};

export default useEnterpriseService;
