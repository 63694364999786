import useApi from '../api';

const useManagerPanelService = () => {
    const { api } = useApi();
    return {
        getAccessLoginPDFReport: (enterprise_id, year) =>
            api.get(`/access_login_pdf?year=${year}&enterpriseId=${enterprise_id}`, { responseType: 'blob' }),

        getUsersPDFReport: (enterprise_id, currentUserRole) =>
            api.get(`/enterprise/${enterprise_id}/employees/employees_enterprise_pdf?currentUserRole=${currentUserRole}`, {
                responseType: 'blob'
            }),

        getPropertiesPDFReport: (enterprise_id, date_properties, date_collaborators, filterPerAllOrBlocked) =>
            api.get(
                `/property/get_properties_month_pdf/${enterprise_id}?date_properties=${date_properties}&date_collaborators=${date_collaborators}&filterPerAllOrBlocked=${filterPerAllOrBlocked}`,
                { responseType: 'blob' }
            ),

        getActionPlansPDFReport: (enterprise_id, year) =>
            api.get(`/enterprise/${enterprise_id}/get_action_plans_details_pdf?year=${year}`, { responseType: 'blob' }),

        getVisitsPDFReport: (enterprise_id, date_visits, date_collaborators) =>
            api.get(
                `/user-events/get_properties_visits_pdf/${enterprise_id}?date_visits=${date_visits}&date_collaborators=${date_collaborators}`,
                { responseType: 'blob' }
            ),
        getCheckAgroPDFReport: (enterprise_id, year) =>
            api.get(`/enterprise/${enterprise_id}/get_check_agro_details_pdf?year=${year}`, { responseType: 'blob' }),

        getReportsPDFReport: (enterprise_id, date_reports, year_collaborators, month_collaborators, currentUserRole) =>
            api.get(
                `/report/get_reports_month_pdf/${enterprise_id}?date_reports=${date_reports}&year_collaborators=${year_collaborators}&month_collaborators=${month_collaborators}&currentUserRole=${currentUserRole}`,
                { responseType: 'blob' }
            ),

        getPerformancePDFReport: (enterprise_id, date) =>
            api.get(`/enterprise/collaborator_performance_pdf/${enterprise_id}?date=${date}`, { responseType: 'blob' })
    };
};

export default useManagerPanelService;
