import React from 'react';

import { Grid, MenuItem, TextField } from '@mui/material';

import ButtonCallAction from '@/components/BI/buttonCallAction';
import { Ranking } from '@/components/Enterprise/Ranking';
import { EmployeeProfileModal } from '@/components/molecules/modals/EmployeeProfileModal';
import { Loading } from '@/pages/Common/EnterpriseDetails/EnterprisePage/tabs/Properties/components/PropertiesPerConsultant/Loading';
import { ReactComponent as FileZipIcon } from '@assets/icons/FileZipIcon.svg';
import { ReportZipReportsRequestModal } from '@components/molecules/modals/ReportZipReportsRequestModal';

import { Div } from '../../styles';
import { useReportsPerConsultant } from './hooks';

const MONTHS = [
    {
        label: 'Janeiro',
        value: 1
    },
    {
        label: 'Fevereiro',
        value: 2
    },
    {
        label: 'Março',
        value: 3
    },
    {
        label: 'Abril',
        value: 4
    },
    {
        label: 'Maio',
        value: 5
    },
    {
        label: 'Junho',
        value: 6
    },
    {
        label: 'Julho',
        value: 7
    },
    {
        label: 'Agosto',
        value: 8
    },
    {
        label: 'Setembro',
        value: 9
    },
    {
        label: 'Outubro',
        value: 10
    },
    {
        label: 'Novembro',
        value: 11
    },
    {
        label: 'Dezembro',
        value: 12
    }
];

const ReportsPerConsultant = ({ idEnterprise, yearsOptions, setConsultantYearFilter, setConsultantMonthFilter }) => {
    const {
        yearFilter,
        monthFilter,
        columnCount,
        maxColumns,
        perColumn,
        data,
        loading,
        isLoadingReportZipRequest,
        selectedConsultant,
        requestZipModal,
        currentUserRole,
        setSelectedConsultant,
        setRequestZipModal,
        requestZipReport,
        handleOpenConsultant,
        changeMonthFilter,
        changeYearFilter
    } = useReportsPerConsultant({ idEnterprise, yearsOptions, setConsultantYearFilter, setConsultantMonthFilter });

    return loading ? (
        <Loading />
    ) : (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <h3>Consultores / Relatórios aprovados</h3>
                </Grid>
                <Grid
                    container
                    justifyContent={'space-between'}
                    marginLeft={2}
                    marginBottom={'10px'}
                    marginTop={'16px'}
                    gap={2}
                    alignItems={'center'}
                >
                    <Grid item>
                        <Grid container gap={2} alignItems={'center'}>
                            <Grid item>
                                <Div minWidth={'100px'}>
                                    <TextField
                                        fullWidth
                                        size={'small'}
                                        select
                                        value={yearFilter}
                                        onChange={(evt) => changeYearFilter(evt.target.value)}
                                    >
                                        {yearsOptions
                                            ? yearsOptions.map((year) => (
                                                  <MenuItem key={year} value={year}>
                                                      {year}
                                                  </MenuItem>
                                              ))
                                            : null}
                                    </TextField>
                                </Div>
                            </Grid>
                            <Grid item>
                                <Div minWidth={'100px'}>
                                    <TextField
                                        fullWidth
                                        size={'small'}
                                        select
                                        value={monthFilter}
                                        onChange={(evt) => changeMonthFilter(evt.target.value)}
                                    >
                                        {MONTHS
                                            ? MONTHS.map((month) => (
                                                  <MenuItem key={month.value} value={month.value}>
                                                      {month.label}
                                                  </MenuItem>
                                              ))
                                            : null}
                                    </TextField>
                                </Div>
                            </Grid>
                        </Grid>
                    </Grid>
                    {currentUserRole === 4 && (
                        <Grid item>
                            <ButtonCallAction
                                sx={{ fontSize: 16, fontWeight: 700, padding: '16px' }}
                                onClick={requestZipReport}
                                loading={isLoadingReportZipRequest}
                                Icon={FileZipIcon}
                            >
                                Baixar relatórios
                            </ButtonCallAction>
                        </Grid>
                    )}
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        {_.chunk(data.slice(0, maxColumns * perColumn), perColumn).map((column, key) => {
                            return (
                                <Grid key={`column-${key}`} item xs={12} md={12 / columnCount}>
                                    <Ranking
                                        titleKey={'name'}
                                        valueKey={'total_properties'}
                                        valueLabel={'propriedades'}
                                        otherKey={'total_reports'}
                                        otherLabel={'relatórios'}
                                        items={column}
                                        badge
                                        onClickItem={(item) => {
                                            handleOpenConsultant(item.id);
                                        }}
                                    />
                                </Grid>
                            );
                        })}
                    </Grid>
                </Grid>
                <EmployeeProfileModal
                    open={selectedConsultant !== null}
                    handleClose={(e) => {
                        e.stopPropagation();
                        setSelectedConsultant(null);
                    }}
                    profile={selectedConsultant}
                />
            </Grid>
            <ReportZipReportsRequestModal open={requestZipModal} handleClose={() => setRequestZipModal(false)} />
        </>
    );
};

export default ReportsPerConsultant;
