import { formikFormData } from '@/utils/helpers/formikFormData';

import useApi from '../api';

const useReportService = () => {
    const { api } = useApi();

    return {
        create: (data) => api.post('report', formikFormData(data)).then((resp) => resp.data),
        update: (data, id) =>
            api
                .post(
                    `report/${id}`,
                    formikFormData({
                        ...data,
                        _method: 'put'
                    })
                )
                .then((resp) => resp.data),
        delete: (reportId) => api.delete(`report/${reportId}`).then((resp) => resp.data),
        getAllReports: (params) =>
            api
                .get('report/', {
                    headers: { 'Content-Type': 'application/json' },
                    params
                })
                .then((resp) => resp.data),
        getReportPerId: (id) =>
            api
                .get(`report/${id}`, {
                    params: {
                        viewAsConsultant: sessionStorage.getItem('consultant_id')
                    }
                })
                .then((resp) => resp.data),
        showReportPDFPerId: (id) => api.get(`report/${id}/pdf`, { responseType: 'blob' }).then((resp) => resp.data),
        addReportApprovalFiles: (data) => api.post('report_approval_files', formikFormData(data)).then((resp) => resp.data),
        getReportsPerConsultant: (id, year, month, currentUserRole) =>
            api
                .get(`report/get_properties_reports/${id}`, {
                    params: {
                        year,
                        month,
                        currentUserRole
                    }
                })
                .then((resp) => resp.data),
        getCountReportsPerMonth: (id, year, currentUserRole) =>
            api
                .get(`report/get_reports_month/${id}`, {
                    params: {
                        year: year,
                        currentUserRole
                    }
                })
                .then((resp) => resp.data),

        updateReportActionPlanData: (payload, reportId, isEditing) =>
            api.put(`report/update_action_plan_data/${reportId}`, {
                ...payload,
                isEditing
            }),
        getReportsPerConsultantZip: (enterprise_id, year, month) =>
            api.post(`/report_approval_files/generate-enterprise-zip/`, {
                enterprise_id,
                year,
                month
            })
    };
};

export default useReportService;
