import useApi from '../api';
const useCollaboratorService = () => {
    const { api } = useApi();
    return {
        getResource: () => api.get('collaborator/get_resources').then((resp) => resp.data),

        getEmployeesEnterprise: (enterpriseId, currentUserRole) =>
            api
                .get(`enterprise/${enterpriseId}/employees/employees_enterprise?currentUserRole=${currentUserRole}`)
                .then((resp) => resp.data),
        getCollaboratorPerId: (collaboratorId) => api.get(`collaborator/${collaboratorId}`).then((resp) => resp.data),
        updateNumberProperty: (data) =>
            api.post(
                'set_property_number',
                { ...data, _method: 'put' },
                {
                    headers: { 'Content-Type': 'multipart/form-data' }
                }
            )
    };
};

export default useCollaboratorService;
