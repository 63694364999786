import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import Cookies from 'js-cookie';
import moment from 'moment';

import { Grid } from '@mui/material';

import SectionTitle from '@/components/atoms/fonts/SectionTitle';
import { GraphicCard } from '@/components/molecules/cards/GraphicCard';
import WelcomeModal from '@/components/molecules/modals/WelcomeModal';
import useCollaboratorService from '@/services/collaborator';
import useEnterpriseService from '@/services/enterprise';
import useManagerPanelService from '@/services/managerPanel';
import { handleDownloadData } from '@/utils/helpers/downloads';
import Bar from '@components/BI/graphicBar';
import LoadingComponent from '@components/templates/LoadingComponent';

import { Section } from '../../../styles';
import { PaperContainer, PaperContainerSmall, PaperCount, PaperTitle } from './styles';

const COLLABORATOR_SENIORITY = [['Trainee'], ['Júnior'], ['Pleno'], ['Sênior']];

const Users = ({ idEnterprise }) => {
    const [loading, setLoading] = useState(true);
    const [employeesCount, setEmployeesCount] = useState(null);
    const [graphBarData, setGraphBarData] = useState([]);
    // const [informationModal, setInformationModal] = useState(false);
    const { getGeneralData } = useEnterpriseService();
    const { getUsersPDFReport } = useManagerPanelService();
    const collaboratorService = useCollaboratorService();
    const user = useSelector((state) => state.auth.user);
    const [redirect, setRedirect] = useState('');
    const currentUserRole = Number(sessionStorage.getItem('currentUserRole'));

    const dispatch = useDispatch();
    const { clickedReportButton } = useSelector((state) => state.managerPanelReducer);

    const options = {
        chartArea: { width: '80%', height: '80%' },
        explorer: { axis: 'horizontal', keepInBounds: false },
        orientation: 'horizontal',
        legend: { position: 'none' },
        fontSize: 12,
        tooltip: {
            ignoreBounds: false,
            textStyle: {
                bold: true
            }
        }
    };

    function handleGraphBarData(data) {
        const DEFAULT_SENIORITY = {
            Trainee: 0,
            Junior: 0,
            Pleno: 0,
            Senior: 0
        };
        const result = [
            [
                '',
                '',
                { role: 'style' },
                {
                    type: 'string',
                    role: 'tooltip',
                    p: { html: true }
                }
            ]
        ];
        let totalDataLength = 0;
        for (let i = 0; i < data.length; i++) {
            Object.keys(data[i]).map((el) => {
                totalDataLength += data[i][el];
                DEFAULT_SENIORITY[el] = data[i][el];
            });
        }

        for (let i = 0; i < COLLABORATOR_SENIORITY.length; i++) {
            const seniority = COLLABORATOR_SENIORITY[i][0];
            const currentColor =
                seniority === 'Trainee'
                    ? '#DAF3A3'
                    : seniority === 'Júnior'
                    ? '#B8D873'
                    : seniority === 'Pleno'
                    ? '#55A559'
                    : seniority === 'Sênior'
                    ? '#3A6647'
                    : '#ffffff';

            const value =
                DEFAULT_SENIORITY[
                    String(seniority)
                        .normalize('NFD')
                        .replace(/[\u0300-\u036f]/g, '')
                ];

            const percentage = parseInt(value);
            const percentageInfo = parseInt((value / totalDataLength) * 100);
            result.push([seniority, percentage, currentColor, `${seniority + ': ' + percentage} - ${percentageInfo}%`]);
        }
        setGraphBarData(result);
    }

    const handleData = async () => {
        try {
            setLoading(true);
            let currentUserRole = Number(sessionStorage.getItem('currentUserRole'));
            const { data } = await getGeneralData(idEnterprise, currentUserRole);

            if (data.enterpriseCollaboratorsLevelsCount && data.enterpriseCollaboratorsLevelsCount.length) {
                handleGraphBarData(data.enterpriseCollaboratorsLevelsCount);
            }

            let response = await collaboratorService.getEmployeesEnterprise(idEnterprise, currentUserRole);
            setEmployeesCount(response);
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    const handleReport = async () => {
        try {
            dispatch({ type: 'MANAGER_PANEL_REPORT_IS_LOADING', payload: true });
            const { data } = await getUsersPDFReport(idEnterprise, currentUserRole);

            if (data) {
                const today = moment().format('DD_MM_YYYY');
                handleDownloadData(data, `relatorio_de_usuario_${today}`);
            }
        } catch (error) {
            console.log(error);
        } finally {
            dispatch({ type: 'MANAGER_PANEL_RESET' });
        }
    };

    useEffect(() => {
        handleData();

        // const userHasWelcome = user?.welcome.find((item) => item.role_id === currentUserRole && !item.welcome) ?? false;

        // if (userHasWelcome && [1, 4, 5].includes(userHasWelcome.role_id)) {
        //     setInformationModal(true);
        // }
    }, []);

    useEffect(() => {
        handleRedirect();
    }, []);

    useEffect(() => {
        if (clickedReportButton) {
            handleReport();
        }
    }, [clickedReportButton]);

    const handleRedirect = () => {
        const profile = {
            1: 'admin',
            4: 'coordenador',
            5: 'supervisor'
        };
        setRedirect(`/${profile[currentUserRole]}/empresa/colaboradores/${idEnterprise}`);
    };

    return (
        <>
            <Section>
                {!loading ? (
                    <>
                        <Grid container spacing={3}>
                            <Grid item xs={9.5}>
                                <GraphicCard title="Distribuição dos Consultores / Senioridade" height="450px">
                                    <Bar height="100%" width="100%" allData={graphBarData} options={options} />
                                </GraphicCard>
                            </Grid>

                            <Grid item xs>
                                <Grid container spacing={1.8} flexDirection={'column'}>
                                    <Grid item component={Link} to={redirect}>
                                        <PaperContainerSmall center="true">
                                            <PaperTitle>Total Consultores</PaperTitle>
                                            <PaperCount>{employeesCount.consultants}</PaperCount>
                                        </PaperContainerSmall>
                                    </Grid>

                                    <Grid item component={Link} to={redirect}>
                                        <PaperContainerSmall center="true">
                                            <PaperTitle>Total Supervisores</PaperTitle>
                                            <PaperCount>{employeesCount.supervisors}</PaperCount>
                                        </PaperContainerSmall>
                                    </Grid>

                                    <Grid item component={Link} to={redirect}>
                                        <PaperContainerSmall center="true">
                                            <PaperTitle>Total Coordenadores</PaperTitle>
                                            <PaperCount>{employeesCount.coordinators}</PaperCount>
                                        </PaperContainerSmall>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </>
                ) : (
                    <LoadingComponent hideLogo />
                )}
            </Section>
            {/* <WelcomeModal fromScreen={'homeAdm'} handleClose={() => setInformationModal(!informationModal)} open={informationModal} /> */}
        </>
    );
};

export default Users;
